
<template>
  <TransitionRoot as="template" :show="isPurchaseModalOpen">
      <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="this.closePurchaseModal()">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
          </TransitionChild>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom dark:bg-true-gray-800 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <!-- Close Button -->
              <div v-if="this.slide_index != 4" class="block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="this.closePurchaseModal()">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Slides -->
              <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">
                <!-- Preload images here -->
                <img class="hidden" :src="QR_CODE_IMG" rel="preload" />
                <img class="hidden" :src="SC_IMG" rel="preload"/>

                <!-- STEP 1 -->
                <div v-if="this.slide_index == 0" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Purchase Entrance Exam Reviewer</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="block xl:inline">Instructions for Payment via GCash</span>
                  </h1>
                  <p>
                    You will be guided through the steps for purchasing the Entrance Exam reviewer. Here's a summary before we proceed:
                  </p>
                  <div class="flex items-start my-4 space-x-4 ">
                    <div class="mx-auto font-bold flex-shrink-0 flex items-center dark:text-gray-800 justify-center h-12 w-12 rounded-full bg-yellow-400 sm:mx-0 sm:h-10 sm:w-10">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
                      </svg>
                    </div>
                    <p>
                      Scan the GCash QR Code to make the payment. It will be shown in the next slide.
                    </p>
                  </div>
                  
                  <div class="flex items-start my-4 space-x-4 my-8">
                    <div class="mx-auto flex-shrink-0 flex items-center dark:text-gray-800 justify-center h-12 w-12 rounded-full bg-yellow-400 sm:mx-0 sm:h-10 sm:w-10 mt-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                      </svg>
                    </div>
                    <p>
                      Once payment is made, take a <strong>screenshot</strong> of the successful transaction in your GCash app for your copy and input the <strong>Ref. No. of your receipt</strong>. Click the <strong>"Payment Sent!"</strong> button once done. 
                    </p>
                  </div>
                  <div class="flex items-start my-4 space-x-4 my-8">
                    <div class="mx-auto flex-shrink-0 flex items-center dark:text-gray-800 justify-center h-12 w-12 rounded-full bg-yellow-400 sm:mx-0 sm:h-10 sm:w-10 mt-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                      </svg>
                    </div>
                    <p>
                      Entrance Exam access will be granted after payment is received and confirmed (usually within 5 minutes). Expect an email confirmation within 24 hours after purchase.
                    </p>
                  </div>
                  <!-- <p class="my-8">
                    Click "Continue" below to proceed with the payment.
                  </p> -->
                  <!-- <p class="my-8 text-red-400">
                    <strong class="text-sm mr-2 text-red-900 bg-red-500 px-2 py-0.5 animate-pulse">IMPORTANT</strong>Please don't forget to add your <strong>email address</strong> as "message" when sending the payment in GCash. We need this to verify your payment.
                  </p> -->
                  <button @click="this.nextStep(1)" class="mt-2 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    Continue
                  </button>    
                </div>

                <!-- STEP 2 -->
                <div v-if="this.slide_index == 1" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Purchase Entrance Exam Reviewer</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="dark:text-yellow-200 text-yellow-400">Step 1:</span>{{ ' ' }}
                    <span class="">Scan the QR Code</span>
                  </h1>
                  <div :class="[(!isLoaded) ? 'animate-pulse' : '', 'rounded-xl bg-true-gray-100 dark:bg-gray-300 h-80 my-8']">
                    <img :src="QR_CODE_IMG" @load="isLoaded = true" alt="MTP QR Code 999" class="max-h-80 mx-auto" />
                  </div>
                  <p>
                    Open your GCash App and scan the QR code above. This should take you to the GCash Express Send page where the payment details are already populated. If there are any issues with the QR Code, you can send the PHP {{ REV_PRICE }} payment to GCash number <strong class="text-yellow-500 dark:text-yellow-400">{{ gcashPhoneNum }}</strong>, or contact your Endorser for further assistance. Click the button below once done. 
                  </p>
                  <button @click="this.nextStep(2)" class="mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    Proceed To Next Step
                  </button>
                  <div class="mt-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                    <a href="#" @click="this.nextStep(0)">Go back</a>
                  </div>
                </div>

                <!-- STEP 3 -->
                <!-- <div v-if="this.slide_index == 2" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Purchase Entrance Exam Reviewer</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="dark:text-yellow-200 text-yellow-400">Step 2:</span>{{ ' ' }}
                    <span>Add Your Email and Send Payment</span>
                  </h1>
                  <img class="-mt-4 mb-8 sm:w-4/6 mx-auto" @load="isLoaded = true" src="https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fmockups%2Fskilltech-gcash-screenshot-2999-step2.png?alt=media&token=6455890a-dbbf-4f4e-a9b5-b7f9468e98f5" />
                  <p>
                    Please add your account <strong class="text-yellow-500 dark:text-yellow-400">email address</strong> in the GCash payment <strong>message</strong> so we can confirm it's you (see screenshot above for reference). Click the button below once you're done.
                  </p>

                  <button @click="this.nextStep(3)" class="mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    Proceed To Next Step
                  </button>
                  <div class="mt-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                    <a href="#" @click="this.nextStep(1)" >Go back</a>
                  </div>
                  
                </div> -->

                <!-- STEP 4 -->
                <div v-if="this.slide_index == 2" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Purchase Entrance Exam Reviewer</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="dark:text-yellow-200 text-yellow-400">Step 2:</span>{{ ' ' }}
                    <span>Enter your Reference Number below</span>
                  </h1>
                  <img :src="SC_IMG" alt="MTP Screenshot Code 999" class="-mt-4 mb-8 sm:w-4/6 mx-auto" />
                  <p>
                    Don't forget to take a <strong class="text-yellow-500 dark:text-yellow-400">screenshot</strong> of the successful transaction in your GCash app for your copy and save it. Input the <strong class="text-yellow-500 dark:text-yellow-400">Ref. No. of your receipt</strong> below (see sample screenshot above). Once done, click the <strong>"Payment Sent!"</strong> button below to complete the payment. 
                  </p>
                  <div class="mt-4">
                    <input v-model="refno" type="text" name="refno" id="refno" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Enter your 13-digit Reference Number (i.e. Ref. No.) here"/>
                  </div>
                  <button :disabled="!isRefNoValid" @click="this.prepareToOnboard()" class="disabled:bg-gray-400 mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    Payment Sent! 
                  </button>
                  <div class="mt-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                    <a href="#" @click="this.nextStep(1)" >Go back</a>
                  </div>           
                </div>

                <!-- STEP 5 -->
                <div v-if="this.slide_index == 4" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Purchase Entrance Exam Reviewer</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span v-if="!readyToExit" class="block xl:inline">One moment please...</span>
                    <span v-else class="block xl:inline">Awesome! <span class="dark:text-yellow-200 text-yellow-400">Thank You</span> for your purchase.</span>
                  </h1>
                  <p>
                    We are excited for you to start your review! Your Entrance Exam access will be granted after payment is received and confirmed (usually within 5 minutes). Expect an email confirmation within 24 hours! If you have any concerns, please reach out to us via email at <strong>support@skilltech.ph</strong>.
                  </p>

                  <div v-if="!readyToExit" class="relative pt-1 mt-8">
                    <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-yellow-200">
                        <div
                        :style="`width: ${progress}%`"
                        class="transition-all duration-500 ease-in-out shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500"
                        ></div>
                    </div>
                  </div>
                  
                  <div class="bg-yellow-50 overflow-hidden rounded-lg text-warm-gray-500 text-left mt-8 max-w-full">
                    <div class="px-4 py-5 sm:py-8 sm:px-9 flex space-x-2 items-start">
                      <div>
                        <LightBulbIcon class="text-yellow-400 w-5 h-5" />
                      </div>
                      <p class="text-yellow-700">
                        <strong>Tip:</strong> {{tips[current]}}
                      </p>
                    </div>
                  </div>
                  
                  <button @click="this.closePurchaseModal()" :disabled="!readyToExit" class="mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-lg font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:pointer-events-none disabled:cursor-auto">
                    Close
                  </button>
                </div>

              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
</template>
 
<script>
// Search for 'switch-gcash-code' and the files that need to be updated will be shown.

// John GCASH QR Code and num
// import QR_CODE_IMG from '@/assets/images/qr/QR-2999.png';
// const GCASH_PHONE_NUM = "09062846028"

// Veen GCASH QR Code and num
import QR_CODE_IMG from '@/assets/images/qr/bogs/QR-2999.png';
const GCASH_PHONE_NUM = "09058562354"

import SC_IMG from '@/assets/images/sc/SC-2999.png';

const REV_PRICE = 2999;
const REV_DURATION = 30;
const REV_DURATION_WORDS = '1 Month';

import moment from "moment";
import firebase from "@/firebase/config";
// import "firebase/functions";
import "firebase/auth";
import "firebase/firestore";
const db = firebase.firestore();

import { LightBulbIcon, XIcon } from "@heroicons/vue/solid";
import { homeData } from "@/data/home.data"

// import mtpQrCode from '../assets/images/mtp-qr-999.png';
// import mtpScCode from '../assets/images/mtp-sc-999.png';

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
export default {
  props: ["isPurchaseModalOpen", "user"],
  components: {
    LightBulbIcon, XIcon,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  data(){
    return {
      // ui data
      // mtpQrCode: mtpQrCode,
      // mtpScCode: mtpScCode,
      isLoading: false,
      slide_index: 0,
      progress: 0, // progress bar
      readyToExit: false,
      tips: homeData.tips,
      current: 0,
      isLoaded: false,
      refno: "",
      QR_CODE_IMG,
      SC_IMG,
      REV_PRICE,
      gcashPhoneNum: GCASH_PHONE_NUM
    };
  },
  computed: {
    isRefNoValid(){
      if(this.refno.length < 13 ) return false  // invalid if less than 13 chars
      if(this.refno.length > 13 ) return false  // invalid if more than 13 chars
      if(isNaN(this.refno)) return false        // invalid if not a number
      return true
    },
  },
  methods: {
    closePurchaseModal() {
      if(this.slide_index == 4){
        if(!this.readyToExit){
          return
        }else{
          /**
           * We need to reload the window after the user successfully
           * purchased a reviewer. This way, the updated userInfo will be 
           * fetched. They can then access CAAP 101 immediately after they
           * purchased.
           */
          this.$emit("closePurchaseModal");
          // location.reload();
        }
      }
      
      this.$emit("closePurchaseModal");
      setTimeout(() => {
        this.slide_index = 0;  
      }, 500);      
      
    },
    nextStep(index){
      this.slide_index = index;
      document.querySelector("body").scrollTop;
    },
    
    async prepareToOnboard(){
      // let isValidEndorserCode = await userService.isValidEndorserCode(db, this.user.endorser)
      // if(!isValidEndorserCode){
      //   alert("Endorser code not found")
      // }else{
        // this.user.level = (this.isStudent) ? 'Undergraduate' : '';
        // console.log("user: ", this.user)
        
        this.nextStep(4) // navigate to the final slide
        this.onboardUser(); // run loaders and tips
      // }    
    },

    onboardUser(){
            
      var current_progress = 0;
      var step = 0.05; // the smaller this is the slower the progress bar

      console.log("Processing payment...")

      // run interval for progress bar
      const interval = setInterval( () => {
          current_progress += step;
          this.progress = Math.round(Math.atan(current_progress) / (Math.PI / 2) * 100 * 1000) / 1000
          if (this.progress >= 100){
              clearInterval(interval);
          }else if(this.progress >= 2) {
              step = 1.0
          }
      }, 100);

      // run interval for tips
      const tipsInterval = setInterval(() => {
        this.current = (this.current < this.tips.length-1) ? this.current+1 : 0;
        console.log(this.current)
      }, 4000);
      
      let _reviewers = {...this.user.reviewers};
      _reviewers.mtp = 'paid';

      let _mtp_data = {
        purchase_date: moment().valueOf().toString(),
        gcash_refno: this.refno
      }

      _mtp_data.finalPrice = REV_PRICE;
      _mtp_data.duration = REV_DURATION;
      _mtp_data.durationInWords = REV_DURATION_WORDS;
      
      // Update User Data
      db.collection("users").doc(this.user.id).update({ 
        reviewers: _reviewers,
        mtp_data: _mtp_data
      })
      .then(() => { 
        console.log("Successfully updated user") 
        
        clearInterval(tipsInterval); 
        clearInterval(interval);
        
        // enable buttons and everything
        this.progress = 100; // set progress to 100
        
        setTimeout(() => {
          // add 1 second before removing the loader
          this.readyToExit = true;
          this.user.reviewers.mtp = 'paid';
          this.user.mtp_data = _mtp_data;
        }, 1000)

      })
      .catch((error) => { console.error("Error updating user data: ", error)  });

    },
  },
};
</script>